import React from 'react';
import styled from 'styled-components';
import feelingoLoader from '../assets/images/spinner-circle.gif';

const SpinnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
`;


const SpinnerSearch = ({ label }) => (
  <SpinnerWrapper>
    <img
      style={{ maxHeight: '240px', maxWidth: '200px' }}
      src={feelingoLoader}
      alt="loading..."
    />
  </SpinnerWrapper>
);

export default SpinnerSearch;
