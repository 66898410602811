import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import 'moment/locale/fr';
import { media } from 'ui';
import { useTranslation } from 'react-i18next';
import iconMap from '../assets/images/IconMap.svg';
import FiltersModal from 'components/FiltersModal';
import iconFilter from '../assets/images/IconFilter.svg';
import { PopoverMenu } from 'ui/popover';
import Dropdown from 'components/Dropdown';
import RangeSlider from 'components/RangeSlider';
import iconPrix from '../assets/images/IconPrix.svg';

const ButtonCarte = styled.button`
  border: 1px solid #1a1a1a26;
  border-radius: 28px;
  height: 42px;
  background-color: black;
  margin-left: 15px;
  ${media.desktop`
      background-color: #FFFFFF;
    `};
  ${props =>
    props.variant === 'priceAndFilter' &&
    css`
      ${media.mobile`
      background-color: #FFFFFF;
    `};
      ${media.tablet`
      background-color: #FFFFFF;
    `};
    `}
`;

const ContainerButtonCarte = styled.div`
  position: fixed;
  z-index: 2;
  left: 0px;
  bottom: 80px;
  width: 100%;
  display: flex;
  justify-content: center;
  ${media.desktop`
      z-index:0;
      position: unset;
      bottom:unset;
      width:initial;
      display:block;
    `};
`;
const ContainerButtonFilterAndPrice = styled.div`
  position: absolute;
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;

  ${media.tablet`
    width: initial;
`};
  ${media.desktop`
    position: unset;
    bottom: unset;
    width: initial;
`};
`;

const BlockButton = styled.div`
  display: inline-flex;
`;

const TextCarte = styled.span`
  text-align: right;
  font: normal normal bold 14px/18px Muli;
  letter-spacing: 0px;
  text-align: center;
  margin-right: 10px;
  margin-left: 10px;
  ${media.mobile`
    color: white;
    ${props =>
      props.variant === 'priceAndFilter' &&
      css`
        color: #1a1a1a;
      `}
  `};
  ${media.tablet`
    color: white;
    ${props =>
      props.variant === 'priceAndFilter' &&
      css`
        color: #1a1a1a;
      `}
  `};
  ${media.desktop`
    color: #1A1A1A;
  `};
`;

const IconeButton = styled.img`
  margin-left: 5px;
  margin-right: 10px;
  vertical-align: baseline;
`;

const DropItem = styled.div`
  font-size: 14px;
  font-weight: 700;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
  transition: box-shadow 100ms ease;
  color: ${({ theme }) => theme.text};

  ${props =>
    props.variant === 'hero' &&
    css`
      border-color: #fff;
      color: #fff;
    `}
`;

const ButtonMaps = ({
  handleVisibilityMap,
  visibilityMap,
  showToggleButton,
  MIN,
  MAX,
  handleFilterPrice,
  showResultsFilter
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => {
    setIsOpen(false);
  };

  const { t } = useTranslation();

  return (
    <div>
      <BlockButton>
        {showResultsFilter ?
          <ContainerButtonFilterAndPrice>
            {Boolean(MIN) && (
              <ButtonCarte variant="priceAndFilter">
                <Dropdown
                  inHeader
                  // alignment="left"
                  rangeSlider
                  trigger={
                    <DropItem>
                      <TextCarte variant="priceAndFilter">
                        {t('rangeSlide.price')}
                      </TextCarte>
                      <IconeButton src={iconPrix} alt="medium" />
                    </DropItem>
                  }
                >
                  <PopoverMenu>
                    <RangeSlider
                      MIN={MIN}
                      MAX={MAX}
                      handleFilterPrice={handleFilterPrice}
                    />
                  </PopoverMenu>
                </Dropdown>
              </ButtonCarte>
            )}
            <ButtonCarte onClick={() => setIsOpen(true)} variant="priceAndFilter">
              <TextCarte variant="priceAndFilter">
                {t('hotel.filters.filterTitle')}
              </TextCarte>
              <IconeButton src={iconFilter} alt="medium" />
            </ButtonCarte>
            <FiltersModal isOpen={isOpen} onClose={onClose} />
          </ContainerButtonFilterAndPrice>
          :
          ''
        }
        {showToggleButton && (
          <ContainerButtonCarte>
            <ButtonCarte isActive={visibilityMap} onClick={handleVisibilityMap}>
              <TextCarte>
                {visibilityMap ? t('searchBar.hide') : t('searchBar.show')}
              </TextCarte>
              <IconeButton src={iconMap} alt="medium" />
            </ButtonCarte>
          </ContainerButtonCarte>
        )}
      </BlockButton>
    </div>
  );
};

export default ButtonMaps;
