import React, {
  useState,
  useRef,
  useMemo,
  useCallback,
  useContext,
  useEffect,
} from 'react';
import useInterval from 'use-interval'
import { useAsync } from 'react-async';
import AuthContext from 'contexts/auth';
import { get, omit, orderBy, times, constant, round } from 'lodash';
import differenceInDays from 'date-fns/difference_in_days';
import SEO from '../components/seo';
import Layout from '../components/Layout';
import styled, { css } from 'styled-components';
import { TitleContainer } from 'ui/containers';
import { MapContainer, SearchMap } from 'components/MyMap';
import { media, SpinnerContainer, TextLink as RawTextLink } from 'ui';
import HotelCards from 'components/HotelCards';
import { parseSearch } from 'utils';
import { parseBookingDate } from 'utils/date';
import HotelMarker from 'components/Markers/HotelMarker';
import { searchHotels } from 'api/hotels';
import { getAccommodation } from 'api/accommodations';
import { useTranslation } from 'react-i18next';
import SpinnerSearch from 'components/SpinnerSearch';
import { useDataLayer, useDataLayerBuilder, useGTM } from '@tymate/react-gtm';
import PageContext from 'contexts/page';
import differenceInMinutes from 'date-fns/difference_in_minutes';
import sanitizeHtml from 'sanitize-html';
import SearchBarResult from 'components/Search/SearchBarAccueil';
import highlighting from '../assets/images/highlighting.png';
import ButtonMaps from 'components/ButtonMaps';
import { useMedia } from 'react-use';
import { useQueryParams } from 'hooks';
import { useSearchParam } from 'react-use';
import { graphql } from 'gatsby';
import Content from 'components/Content';

export const SplitContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-around;
  min-height: 40vh;
  ${media.medium`
    flex-direction: row;
    justify-content: space-around
  `}
  ${media.mobile`
  flex-direction: column;
  `}
  ${media.tablet`
  flex-direction: column;
  `}
  ${media.desktop`
  display: flex;
    flex-direction: row;

  `}
`;

const LeftContainer = styled.div`
  padding-top: ${({ theme }) => theme.spacing(2)};
  margin-top: ${({ theme }) => theme.spacing(0)};

  > * {
    padding-top: 0;
    + * {
      padding-top: ${({ theme }) => theme.spacing(3)};
    }
  }

  width: 85%;
  margin-right: auto;
  margin-left: auto;

  ${props =>
    props.visibilityMap &&
    css`
      width: 55%;
    `}

  ${media.mobile`
    padding: ${({ theme }) => theme.spacing(0)};
    width: 100%;
    margin-top: ${({ theme }) => theme.spacing(3)};
 `}

  ${media.tablet`
    margin-top: -32px;
 `}
`;

const RightContainer = styled.div`
  flex-shrink: 0;
  width: 30vw;
  height: calc(100vh - 165px);

  ${media.desktop`
    display: block;
    margin-top: ${({ theme }) => theme.spacing(0)};;
    right: 0px;
    top: 0px;
    height: 100%;
    width: 40% !important;
  `};

  ${media.tablet`
    width:100%;
`};

  ${media.mobile`

  position: relative;
  width: 100%;
  top:80px;
  z-index:1;
`};
`;

const MapWrapper = styled.div`
  height: 100%;
  width: 100%;
  color: #fff;
  position: fixed;
  top: 0px;

  ${media.mobile`
position:relative;

`};
  ${media.desktop`
width:40%;
position: fixed;

`};
`;

const ActionLine = styled.div`
  ${media.desktop`
  display: flex;
    flex-direction: row;
    justify-content: space-between
  `}
`;

const SentenceWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing(4)};
  width: 50%;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  ${media.mobile`
  width: 100%;
`};
`;

const Sentence = styled.div`
  margin-top:20px;
  font-size: 25px;
  text-align: center;
`;

const WaitSentence = styled.div`
  font-size: 16px;
  text-align: center;
  color: #a5a5a5;
  padding-top: 10px;
`;

const WaitSentenceReload = styled.div`
  text-align: center;
  font-size: 14px/22px;
  margin-top: 10px;
`;

const TextLink = styled(RawTextLink)`
  color: #e0791a;
  text-decoration: underline;
`;

const BlockEntete = styled.div`
  background-color:#F7F7F7;
  position: relative;
  z-index: 3;
  h1{
    text-align:center;
    ${media.desktop`
    font-size: ${({ theme }) => theme.spacing(3)};
    `}
  }

  padding-bottom: 50px;
  padding-top: 50px;
}
  ${media.desktop`
    top: -70px;
  `};

  ${media.tablet`
    margin-top: -32px;
 `}

  ${media.desktop`
    margin-top: 0;
 `}
`;

const WordHighLight = styled.div`
  background-image:url('${highlighting}');
  background-repeat: no-repeat;
  background-size:100% 100%;
  margin-top:0px !important;
  padding:10px;
  display: inline;
  color:white;
`;

const fetchSearchResults = async ({ lang, search, cacheKey }) => {
  try {
    const cache = localStorage.searchCache
      ? JSON.parse(localStorage.searchCache)
      : {};
    const { key, expiresAt, value } = cache || {};
    if (cacheKey === key) {
      if (differenceInMinutes(new Date(), expiresAt) <= 20) {
        return { datas: value, fromCache: true };
      }
    }
    throw new Error('Missing from cache');
  } catch (e) {
    const datas = await searchHotels({ lang, search });
    return {
      datas,
      fromCache: false,
    };
  }
};

const cacheSearchResults = ({ results, cacheKey }) => {
  if (results.fromCache) {
    return;
  }
  localStorage.setItem(
    'searchCache',
    JSON.stringify({
      key: cacheKey,
      expiresAt: new Date(),
      value: results?.hotels,
    }),
  );
};

export const query = graphql`
  query($language: String!) {
    generalInformation(language: { eq: $language }) {
      textFindYourTrip
    }
    allExpectationPhrase(
      filter: { language: { eq: $language } }
    ) {
      nodes {
        displayName
        language
      }
    }
  }
`;

const Search = ({ data: {
  allExpectationPhrase: { nodes: expectationPhrases = [] }, generalInformation
  },location }) => {
  const { language } = useContext(PageContext);
  const { isAuthenticated } = useContext(AuthContext);
  const [currentPhraseId, setCurrentPhraseId] = useState(0);
  const { textFindYourTrip } = generalInformation;

  useInterval(() => {
    setCurrentPhraseId(currentPhraseId+1 < expectationPhrases.length ? currentPhraseId+1:0);
  }, 5000);


  useDataLayerBuilder(() => ({
    pageType: 'search',
  }));

  const mapRef = useRef();
  const search = useMemo(() => parseSearch(location.search.substring(1)), [
    location,
  ]);
  const cacheKey = location.search;

  const [visibilityMap, setVisibilityMap] = useState(true);
  const { t } = useTranslation();
  const searchLocation = get(search, 'location') || [];
  const [hoveredCardId, setHoveredCardId] = useState();
  const accomodationId = useSearchParam('your_accommodation');

  const { pushDataLayer } = useGTM();
  const isDesktop = useMedia('(min-width: 1100px)');

  useDataLayer({
    checkinDate: search?.startDate,
    checkoutDate: search?.endDate,
    searchDays:
      search?.startDate &&
      search?.endDate &&
      differenceInDays(
        parseBookingDate(search?.endDate),
        parseBookingDate(search?.startDate),
      ),
    searchBookingWindow:
      search?.startDate &&
      differenceInDays(parseBookingDate(search?.startDate), new Date()),
    searchTerms: searchLocation?.[0],
    searchLocation: searchLocation?.[1],
  });

  const [query, bbox] = searchLocation;
  const coordinates = bbox ? bbox.split(',') : null;

  const queryParams = useQueryParams();
  const {
    type,
    style,
    category,
    stars,
    environment,
    sustainability,
  } = queryParams;

  const { data: { datas } = {}, isPending } = useAsync({
    promiseFn: fetchSearchResults,
    lang: language,
    cacheKey,
    search: {
      startDate: search.startDate,
      endDate: search.endDate,
      guests: search.ageCategories?.adults,
      children: search.ageCategories?.children,
      infants: search.ageCategories?.infants,
      childrenAges: times(search.ageCategories?.children, constant(16)),
      profileId: search.profile,
      query:
        search?.countryId || search?.regionId !== undefined ? undefined : query,
      lang: 'fre',
      currency: 'EUR',
      your_accommodation: accomodationId,
      includes: [
        'primary_attachment',
        'city',
        'city.country',
        'sustainable',
        'official_categories',
      ],
      filters: {
        ...(coordinates
          ? {
              location: {
                expand: 50,
                topLeft: {
                  lat: coordinates[0],
                  lon: coordinates[3],
                },
                bottomRight: {
                  lat: coordinates[2],
                  lon: coordinates[1],
                },
              },
            }
          : null),
        ...(Boolean(search?.countryId) && {
          country_id: search.countryId,
        }),
        ...(Boolean(search?.cityId) && {
          city_id: search.cityId,
        }),
        type: Boolean(type) ? type : null,
        style: Boolean(style) ? style : null,
        category: Boolean(category) ? category : null,
        stars: Boolean(stars) ? stars : null,
        sustainability: Boolean(sustainability) ? sustainability : null,
        environment: Boolean(environment) ? environment : null,
        ...(Boolean(search?.regionId) && {
          region_id: search.regionId,
        }),
      },
    },
    watch: search,
    onResolve: useCallback(
      results => {
        if (results?.hotels?.length) {
          pushDataLayer({
            event: 'searchSuccess',
            searchContentIds: results?.hotels?.map(({ id }) => id),
          });
          cacheSearchResults({ results, cacheKey });
          pushDataLayer({
            ecommerce: {
              impressions: results?.hotels.map((hotel, index) => ({
                id: hotel?.id,
                name: hotel?.displayName,
                brand: 'Feelingo',
                category: 'Hotel',
                position: index + 1,
              })),
            },
          });
        } else {
          pushDataLayer({
            event: 'searchFailure',
          });
        }
      },
      [cacheKey, pushDataLayer],
    ),
    onReject: useCallback(() => {
      pushDataLayer({
        event: 'searchFailure',
      });
    }, [pushDataLayer]),
  });

  const noFilterHotels = datas?.body || [];
  const [hotels, setFilterPrice] = useState([]);

  useEffect(() => {
    if (noFilterHotels) {
      setFilterPrice(noFilterHotels);
    }
  }, [isPending]); // eslint-disable-line react-hooks/exhaustive-deps

  const { data: accommodation } = useAsync({
    promiseFn: getAccommodation,
    id: accomodationId,
    language,
  });

  const sentence = expectationPhrases.length>currentPhraseId ? expectationPhrases[currentPhraseId].displayName :'';
  const TabPrice = [];

  for (let i = 0; i < noFilterHotels.length; i++) {
    TabPrice.push(round(noFilterHotels[i].minPrice, 0));
  }

  const getPricingMinMax = values => {
    let min = values[0];
    let max = values[1];
    let newHotels = [];
    for (var i = 0; i < noFilterHotels.length; i++) {
      if (
        noFilterHotels[i].minPrice >= min &&
        noFilterHotels[i].minPrice <= max
      ) {
        newHotels.push(noFilterHotels[i]);
      }
    }
    setFilterPrice(newHotels);
  };

  return (
    <Layout variant="search">
      <SEO title={t('pageTitle.search')} />
      <BlockEntete>
        <h1>
          {' '}
          {t('search.titlePart1')}{' '}
          <WordHighLight>{t('search.titlePart2')}</WordHighLight>{' '}
          {t('search.titlePart3')}
        </h1>
        <Content html={textFindYourTrip} isDescription/>
        {accomodationId ? '' : <SearchBarResult isResultPage />}
      </BlockEntete>
      {isPending ? (
        <>
          <SpinnerContainer>
            <SpinnerSearch />
          </SpinnerContainer>
          <SentenceWrapper>
            <Sentence>
              {sanitizeHtml(sentence, {
                allowedTags: [],
                allowedAttributes: {},
              })}
            </Sentence>
            <WaitSentence>{t('search.loadSentence1')}</WaitSentence>
            <WaitSentenceReload>
              {t('search.loadSentence2')}{' '}
              <TextLink href="#">{t('search.linkReload')}</TextLink>
            </WaitSentenceReload>
          </SentenceWrapper>
        </>
      ) : (
        <SplitContainer>
          <LeftContainer visibilityMap={visibilityMap && hotels.length > 0}>
            <ActionLine>
              <TitleContainer bold small noPaddingLeft>
                {accomodationId
                  ? (hotels ?? []).length > 0
                    ? t('search.matchAccommodation', {
                        count: hotels.length,
                        title: accommodation?.yourAccommodation.title,
                      })
                    : t('search.noMatchHotel')
                  : (hotels ?? []).length > 0
                  ? t('search.matchNumberHotelPlural', { count: hotels.length })
                  : t('search.noMatchHotel')}
              </TitleContainer>

              <ButtonMaps
                hasBottomBorder
                showToggleButton={hotels.length > 0}
                variant="search"
                initialValues={search}
                handleVisibilityMap={() => setVisibilityMap(!visibilityMap)}
                handleFilterPrice={getPricingMinMax}
                visibilityMap={visibilityMap}
                MIN={Math.min(...TabPrice)}
                MAX={Math.max(...TabPrice)}
                showResultsFilter = {!accomodationId}
              />
            </ActionLine>
            {!visibilityMap && !isDesktop && (
              <HotelCards
                hasProgressBar={isAuthenticated && Boolean(search.profile)}
                visibilityMap={visibilityMap && hotels.length > 0}
                hotels={orderBy(hotels, 'score', 'desc')}
                searchParams={omit(search, ['location'])}
                onHover={hotelId => setHoveredCardId(hotelId)}
              />
            )}
            {isDesktop && (
              <HotelCards
                hasProgressBar={isAuthenticated && Boolean(search.profile)}
                visibilityMap={visibilityMap && hotels.length > 0}
                hotels={orderBy(hotels, 'score', 'desc')}
                searchParams={omit(search, ['location'])}
                onHover={hotelId => setHoveredCardId(hotelId)}
              />
            )}
          </LeftContainer>
          {visibilityMap && hotels.length !== 0 && (
            <RightContainer>
              <MapWrapper>
                <MapContainer>
                  <SearchMap
                    ref={mapRef}
                    hotels={hotels}
                    markers={hotels
                      .filter(
                        hotel => Boolean(hotel.lat) && Boolean(hotel.lon),
                      )
                      .map(hotel => ({
                        ...hotel,
                        lat: Number(hotel.lat),
                        lng: Number(hotel.lon),
                      }))
                      .map(hotel => (
                        <HotelMarker
                          searchParams={omit(search, ['location'])}
                          hotel={hotel}
                          id={hotel.id}
                          lat={hotel.lat}
                          lng={hotel.lng}
                          isHovered={Boolean(hoveredCardId === hotel.id)}
                        />
                      ))}
                  />
                </MapContainer>
              </MapWrapper>
            </RightContainer>
          )}
        </SplitContainer>
      )}
    </Layout>
  );
};

export default Search;
