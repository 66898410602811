import * as React from 'react';
import { Range, getTrackBackground } from 'react-range';
import { TextField } from 'components/Forms';
import { Formik } from 'formik';
import styled from 'styled-components';
import { Button, Buttons } from 'ui';
import { useTranslation } from 'react-i18next';
import {debounce} from 'lodash';

const STEP = 1;
const TextFields = styled(TextField)`
  margin-top: 5px;
  border-radius: 10px;
  width: 100%;
  color: #000;
  cursor: default;
  font-weight: bold;
`;
const InputBlock = styled.div``;
const BlockText = styled.div``;
const Label = styled.div`
  position: absolute;
  top: 0px;
  color: #000000;
  font-weight: bold;
  margin-top: 20px;
`;
const Reset = styled.span`
  color: #000000;
  cursor: pointer;
  font-weight: bold;
  margin-top: 0.3rem;
`;
const Devise = styled.div`
  position: relative;
  margin-left: 80%;
  top: 43px;
  color: #000;
  font-weight: bold;
`;

const RangeSlider = ({ rtl, MIN, MAX, handleFilterPrice }) => {
  const [values, setValues] = React.useState([MIN, MAX]);
  const [valueChanged, setValueChanged] = React.useState();

  const enforceConstraints = React.useCallback(
    debounce(([min,max],valueChanged) => {
      let newMin=parseInt(min);
      let newMax=parseInt(max);
      let minInRange = parseInt(min) >= MIN && parseInt(min) <= MAX;
      let maxInRange = parseInt(max) >= MIN && parseInt(max) <= MAX;
      if (!minInRange) {
        if (parseInt(min) <= MIN) {
          newMin = MIN;
        } else {
          newMin = newMax;
        }
      }
      if (!maxInRange) {
        if (parseInt(max) <= MIN) {
          newMax = newMin;
        } else {
          newMax = MAX;
        }
      }
      if (minInRange && maxInRange) {
        if (valueChanged === 'min') {
          if (parseInt(newMin)>parseInt(newMax)) {
            newMin=newMax;
          }
        }
        if (valueChanged === 'max') {
          if (parseInt(newMax)<parseInt(newMin)) {
            newMax=newMin;
          }
        }
      }
      if (newMin!==min || newMax!==max) {
        setValueChanged(undefined);
        setValues([parseInt(newMin),parseInt(newMax)]);
      }
    }, 1000),[]
  );

  React.useEffect(() => {
    enforceConstraints(values,valueChanged);
  },[values,enforceConstraints,valueChanged]);


  const { t } = useTranslation();
  const resetForm = () => {
    setValues([MIN, MAX]);
  };
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
      }}
    >
      <Formik initialValues={{ prixMin: '' }}>
        <>
          <InputBlock className="mb-8 grid grid-cols-2 gap-4">
            <BlockText>
              <Label>{t('rangeSlide.min')}</Label>
              <Devise>€</Devise>
              <TextFields name="Min" value={values[0]} onChange={(e) => {setValueChanged('min');setValues([e.target.value,values[1]])}} />
            </BlockText>
            <BlockText>
              <Label>{t('rangeSlide.max')}</Label>
              <Devise>€</Devise>
              <TextFields name="Max" value={values[1]} onChange={(e) => {setValueChanged('max');setValues([values[0],e.target.value])}} />
            </BlockText>
          </InputBlock>
        </>
      </Formik>
      <Range
        values={values}
        step={STEP}
        min={MIN}
        max={MAX}
        rtl={rtl}
        onChange={values => setValues(values)}
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: '36px',
              display: 'flex',
              width: '100%',
            }}
          >
            <div
              ref={props.ref}
              style={{
                height: '5px',
                width: '100%',
                borderRadius: '4px',
                background: getTrackBackground({
                  values,
                  colors: ['#ccc', '#FFA24B', '#ccc'],
                  min: MIN,
                  max: MAX,
                  rtl,
                }),
                alignSelf: 'center',
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ index, props, isDragged }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: '15px',
              width: '15px',
              borderRadius: '10px',
              backgroundColor: '#FFA24B',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: '0px 2px 6px #AAA',
            }}
          ></div>
        )}
      />
      <Buttons variant="rangeSlider">
        <Reset onClick={() => resetForm()}>{t('rangeSlide.reset')}</Reset>
        <Button
          variant="carnet"
          type="submit"
          onClick={() => handleFilterPrice(values)}
        >
          {t('rangeSlide.apply')}
        </Button>
      </Buttons>
    </div>
  );
};

export default RangeSlider;
